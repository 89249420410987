export const ALL_LOGIN_ROUTES = {
    dashboard: 'dashboard',
    amber: "amber",
    anonymous: "anonymous",
    query: "query"
}

export const LOGIN_ROUTE_LABELS = {
    dashboard: "Dashboard",
    amber: "Chat",
    anonymous: "Anonymous Bat",
    query: "AI Assist"
}

export const LOGIN_DROPDOWN_LABEL = "Log in directly to:";

export const LOGIN_PAGE_STRINGS = {
    LOGIN: "Log in",
    EMAIL_ADDRESS: "Email address",
    PASSWORD: "Password",
    LOGIN_WITH_OTP: "Log in with OTP",
    LOGIN_WITH_SSO: "Log in with SSO",
    STANDARD_LOGIN: "Log in with password",
    LOGIN_WITH_MS: "Log in with Microsoft",
    LOGIN_WITH_GOOGLE: "Log in with Google",
    REMEMBER_ME: "Remember me",
    FORGOT_PASSWORD: "Forgot Password?",
    ERROR_MSG_PASSWORD: "Password cannot be empty",
    ERROR_MSG_EMAIL_EMPTY: "Email address cannot be empty",
    ERROR_MSG_EMAIL_INVALID: "Please enter a valid email address",
    EMAIL_PLACEHOLDER: "e.g. name@company.com",
    CONFIRM_EMAIL: "Confirm your email",
    NEXT: "Next",
};

export const FORGOT_PASSWORD_PAGE_STRINGS = {
    VERIFY_EMAIL: "Verify your email",
    CODE_SENT: "We have sent a code to your email:",
    TYPE_CODE: "Type code",
    VERIFY: "Verify",
    CHECK_SPAM: "Remember to check your spam folder.",
    RESEND: "Resend new code",
    SET_PASSWORD: "Set Password",
    UPDATE_PASSWORD: "Update your password and secure your account",
    NEW_PASSWORD: "New Password",
};

export const OTP_LOGIN_PAGE_STRINGS = {
    LOGIN_WITH_OTP: "Log in with OTP",
    MFA_OTP_LOGIN: "Multi-Factor Authentication",
    COUNTRY_CODE: "India (+91)",
    PHONE_PLACEHOLDER: "e.g. 99999 99999",
    MOBILE_NUMBER: "Mobile number",
    INFO_CONTENT: "Login via OTP is only available for <span className='has-text-weight-semibold'>Indian mobile numbers.</span>",
    SEND_OTP: "Send OTP",
    OTP: "OTP",
    ENTER_OTP: "Enter OTP",
    RESEND_OTP: "Resend OTP",
    INVALID_OTP: "Please enter a valid OTP",
    RETRY: "Retry after 30 mins"
};

export const LOGIN_LIST_ITEMS = [
    {
        id: "dashboard",
        label: LOGIN_ROUTE_LABELS.dashboard,
        value: "dashboard",
        tooltip_props: {
            show_tooltip: false,
        },
    },
    {
        id: ALL_LOGIN_ROUTES.amber,
        label: LOGIN_ROUTE_LABELS.amber,
        value: "chat",
        tooltip_props: {
            show_tooltip: false,
        },
    },
    {
        id: ALL_LOGIN_ROUTES.anonymous,
        label: LOGIN_ROUTE_LABELS.anonymous,
        value: "anonymous",
        tooltip_props: {
            show_tooltip: false,
        },
    },
    {
        id: ALL_LOGIN_ROUTES.query,
        label: LOGIN_ROUTE_LABELS.query,
        value: "helpdesk",
    }
]

export const DEFAULT_DROPDOWN_DATA = {
    id: "single-select-test",
    type: "primary",
    field_label: LOGIN_DROPDOWN_LABEL,
    is_required: false,
    title: LOGIN_ROUTE_LABELS.dashboard,
    onApply: () => { },
    list_items: LOGIN_LIST_ITEMS
};

export const LOGIN_ERROR_MSG = {
    amber: "You don't have access to chats.",
    dashboard: "You don't have access to dashboard.",
    query: "You don't have access to AI Assist.",
    anonymous: "You don't have access to anonymous messages."
};
