import React from "react";
import ResetPassword from "../components/resetpassword/ResetPassword"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"


const PasswordReset = () => (
    <Layout mode={true}>
        <SEOComponent title="Amber by inFeedo | Reset Password" />
        <ResetPassword/>
    </Layout>
)

export default PasswordReset
