import React from 'react'
//assets
import { LOGIN_PAGE_STRINGS } from './LOGIN_CONSTANTS'
import { Google, Microsoft } from 'design-system';
// components
import { Button } from 'design-system';

function OAuth({
    OAuth
}) {
    return (
            <>
            <li className='oauth-login'>
                <Button 
                    type="secondary"
                    label={LOGIN_PAGE_STRINGS.LOGIN_WITH_GOOGLE}
                    onClick={() => OAuth('google')}
                    size="large"
                    hasFullWidth
                    iconBeforeLabel={Google}
                />
            </li>
            {/* <li className='oauth-login'>
                <Button 
                    type="secondary"
                    label={LOGIN_PAGE_STRINGS.LOGIN_WITH_MS}
                    onClick={() => OAuth('microsoft')}
                    size="large"
                    hasFullWidth
                    iconBeforeLabel={Microsoft}
                />
            </li> */}
       </>
    )
}

export default OAuth
